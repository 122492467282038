import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import ReactMarkdown from "react-markdown"
import { Container } from "react-bootstrap"

const ArticleTemplate = ({ data }) => (
  <Layout>
    <Img className="hero-image center"
      fluid={data.strapiArticle.image.localFile.childImageSharp.fluid}
      alt={data.strapiArticle.image.alternativeText}
      objectFit="cover"
      objectPosition="50% 50%" />
    <Container>
      <h1 className="font-medium title-font mt-4 text-gray-900 text-lg">{data.strapiArticle.title}</h1>
      <p>opublikował <strong>{data.strapiArticle.author?.username}</strong></p>
      <ReactMarkdown source={data.strapiArticle.content} />
      <Link to="/blog" className="more-posts no-bottom">Pozostałe wpisy</Link>
    </Container>
  </Layout >
)

export default ArticleTemplate

export const query = graphql`
  query ArticleTemplate($id: String) {
    strapiArticle(id: {eq: $id}) {
      title
      content
      image {
        alternativeText
        localFile {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 100) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      author {
        id
        username
      }
    }
  }
  `
